<template>
  <div class="chat-panel">
    <template v-for="item in chatData">
      <div class="chat chat-right" v-if="item.right">
        <div class="right-text-container">
          <span class="date-time">{{ item.right.dateTime }}</span>
          <br>
          <pre class="text-content" >{{ item.right.text }}</pre>
        </div>
        <img :src="userImage"/>
      </div>
      <div class="chat chat-left" v-if="item.left">
        <img src="images/robot/2.png"/>
        <div class="left-text-container">
          <span class="date-time">{{ item.left.dateTime }}</span>
          <br>
          <div class="text-content" ref="mark-down">
            <VueMarkdown :html="true" @rendered="handleRendered" :source="item.left.text"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>

export default ({
  components: {
    VueMarkdown: () => import('vue-markdown'),
  },
  computed: {
    userImage: {
      get() {
        return sessionStorage.getItem('avatar') ? sessionStorage.getItem('avatar') : 'images/robot/1.jpg'
      }
    }
  },
  props: {
    chatData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      chatUid: '',
      hasInit: false,
    }
  },
  methods: {
    handleRendered() {
      if(!this.hasInit){
        this.hasInit =true
        let that = this
        this.$nextTick(() => {
          let elements = document.querySelectorAll(".link-text");
          elements.forEach(element => {
            element.addEventListener('click', () => {
              that.$emit('clickLink', element.innerHTML)
            })
            // }, {once: true});
          })
        })
      }
    },
  },
  mounted() {
  }
})
</script>

<style scoped lang="scss">
.chat-panel {
  padding: 10px 15px 0 15px;
  display: flex;
  flex-direction: column;

  .chat {
    display: flex;
    margin-top: 10px;

    ::v-deep .link-text {
      color: #536976;
      cursor: pointer;
    }

    ::v-deep .link-text:hover {
      opacity: 0.8;
    }

    ::v-deep h1 {
      font-size: 25px;
    }

    ::v-deep h2 {
      font-size: 23px;
    }

    ::v-deep h3 {
      font-size: 21px;
    }

    ::v-deep h4 {
      font-size: 19px;
    }

    ::v-deep h5 {
      font-size: 17px;
    }


    img {
      border-radius: 50%;
      height: 35px;
      width: 35px;
      margin-top: 10px;
      opacity: 0.9;
    }

    .date-time {
      color: #b4bbc4;
    }

    .text-content {
      padding: 7px 10px;
      border-radius: 5px;
      display: inline-block;
      max-width: calc(100% - 45px);
    }
  }

  .chat-left {
    .left-text-container {
      margin-left: 10px;
      text-align: left;
      margin-top: 3px;
      width: 100%;

      ::v-deep p:last-child {
        margin-bottom: 0;
      }
    }

    .text-content {
      background: #fff;
    }
  }

  .chat-right {
    margin-left: auto;
    width: 100%;

    .right-text-container {
      margin-right: 10px;
      text-align: right;
      margin-top: 3px;
      width: 100%;
    }

    .text-content {
      background: #d2f9d1;
      text-align: left;
    }
  }

}
</style>